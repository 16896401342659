import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
const ManualTesting = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="image_container">
          <img
            className="imag"
            src="/images/MANUAL-TESTING.png"
            alt="product development"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="content_container">
            <h2 className="content_heading_title">
              🔍 Unveiling Excellence in Manual Testing 🕵️
            </h2>
            <p>
              At ScryptHub, we believe that thorough manual testing is the
              cornerstone of delivering impeccable software solutions. Our
              dedicated team of manual testers takes pride in their meticulous
              approach to ensure your project's success. 🌟
            </p>
            <p>
              Let's explore the essential elements of our manual testing
              approach:
            </p>
            <div className="content_phases">
              <p>
                <b>1. Requirements Analysis 🧐</b> Before initiating the testing
                phase, we dive deep into understanding your project's
                requirements and functional specifications. This analysis lays a
                robust foundation for our testing strategies.
              </p>
              <p>
                <b>2. Test Environment Setup and Tool Selection 🛠️</b>
                We meticulously configure the test environment and select
                appropriate testing tools, tailored to your project's unique
                characteristics. This careful selection ensures precise and
                accurate testing.
              </p>
              <p>
                <b>3. Test Plan Development 📅</b> A well-structured test plan
                forms the backbone of our testing journey. It outlines the
                objectives, scope, and approach, providing a clear roadmap for
                our testers.
              </p>
              <p>
                <b>4. Critical Transaction Identification 🎯</b> Not all
                functions are equal. We identify and prioritize critical
                transactions within your application, ensuring we focus our
                testing efforts where they matter most.
              </p>
              <p>
                <b>5. Test Case Creation 📄</b> Our expert testers craft
                detailed test cases, designed to cover specific scenarios and
                guarantee comprehensive functional coverage.
              </p>
              <p>
                <b>6. Test Case Execution 🚀</b> Our experienced manual testers
                execute each test case with precision, verifying that your
                application's functions perform as intended.
              </p>
              <p>
                <b>7. Bug Reporting 🐞</b> If any discrepancies or issues arise
                during testing, we promptly report them. Our bug reports are
                detailed and actionable, simplifying the resolution process.
              </p>
              <p>
                <b>8. Comprehensive Testing 🌐</b> Our manual testing
                encompasses various dimensions, including user interface design,
                compatibility across devices and browsers, configuration
                settings, functionality, and localization. This comprehensive
                approach ensures a seamless user experience.
              </p>
              <p>
                <b>9. Documentation Review 📘</b> We go beyond functionality,
                reviewing product specifications and user documentation to
                ensure they accurately represent your application's capabilities
                and features.
              </p>
              <p>
                <b>10. Transparent Reporting 📊</b> Transparency is integral to
                our process. We provide detailed testing status reports,
                including Test Reports, Bug Reports, and Test Documentation.
                These reports keep you informed about our progress and testing
                outcomes.
              </p>
            </div>
            <h3 className="content_heading">
              The Capabilities of Our Manual Testers 🌐
            </h3>
            <p>
              Our team of manual testers is a blend of experience and expertise.
              They bring:
            </p>
            <div className="content_phases">
              <p>
                <b>
                  <span className="emoji">🔬</span>In-Depth Knowledge:
                </b>
                A profound understanding of software functionality, industry
                standards, and best practices.
              </p>
              <p>
                <b>
                  <span className="emoji">👁️</span>Keen Observation:
                </b>
                An eye for detail, detecting even the most subtle issues that
                automated testing may overlook.
              </p>
              <p>
                <b>
                  <span className="emoji">💼</span>Domain Expertise:
                </b>
                Knowledge across diverse domains, enabling us to cater to a wide
                range of industries.
              </p>
              <p>
                <b>
                  <span className="emoji">🤝</span>Collaborative Spirit:
                </b>
                A commitment to working closely with your development team to
                achieve seamless integration and collaboration.
              </p>
            </div>
            <div className="ftext">
              <h2 className="text-center content_heading_title">
                📞 Get Started Today! 📞
              </h2>
              <p>
                With ScryptHub, manual testing is not just a process; it's a
                commitment to delivering excellence in every line of code. Trust
                us to be your guardians of quality assurance, ensuring that your
                software exceeds expectations.
                <Link to="/contact-us"> Contact us </Link> today to learn more
                about how our manual testing prowess can benefit your project!
                🚀 🌟
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualTesting;
