import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";
const Product = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="image_container">
          <img
            className="imag"
            src="/images/product development.png"
            alt="product development"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="content_container">
            <h2 className="content_heading_title">
              Elevate Your Innovation with Our Product Development Services 🚀
            </h2>
            <p>
              At ScryptHub, we are your strategic partner in bringing
              groundbreaking products to life. Our comprehensive product
              development services are designed to transform your ideas into
              market-ready solutions, driving innovation and success. 💡✨
            </p>
            <h3 className="content_heading">
              Concept to Reality: Our Product Development Approach 🌟
            </h3>
            <p>
              Our holistic approach to product development takes you from
              concept to reality:
            </p>
            <div className="content_phases">
              <p>
                <b>1. Ideation and Conceptualization 💭</b> We start by
                collaborating closely with you to understand your vision and
                goals. Our team of experienced product developers works
                hand-in-hand with you to refine concepts, identify
                opportunities, and map out the path to a successful product.
                🤝📝
              </p>
              <p>
                <b>2. Market Research and Validation 📊🔍</b> Informed decisions
                are at the core of our process. We conduct in-depth market
                research and validation to ensure your product aligns with
                market demands and consumer preferences. This step is essential
                in crafting a product that resonates with your target audience.
                🎯👥
              </p>
              <p>
                <b>3. Design and Prototyping ✏️🔧</b> Our design experts create
                captivating and user-friendly product prototypes. These
                prototypes serve as the foundation for your product's visual
                identity and functionality. We believe that a well-designed
                product not only works flawlessly but also looks stunning. 🖼️💎
              </p>
              <p>
                <b>4. Development and Testing 🛠️🧪</b> Our skilled development
                team takes your approved prototype and transforms it into a
                fully functional product. Rigorous testing ensures that your
                product is robust, secure, and ready for market deployment. We
                prioritize both functionality and user experience to ensure your
                product exceeds expectations. 🚀👨‍💻
              </p>
              <p>
                <b>5. Iteration and Optimization 🔄🚀</b> The product
                development process is an iterative journey. We continuously
                refine and optimize your product based on user feedback, market
                trends, and emerging technologies. Our commitment to agility
                ensures your product remains competitive and future-ready. 📈🔝
              </p>
              <p>
                <b>6. Launch and Market Entry 🚀🌍</b> We guide you through the
                product launch process, offering strategies to make a memorable
                entrance into the market. From marketing campaigns to
                distribution logistics, we help you get your product in the
                hands of your target audience. 📣📦
              </p>
              <p>
                <b>7. Post-Launch Support and Growth 🌱📈</b> Our services
                extend beyond the launch date. We provide ongoing support and
                monitoring to address any issues, implement updates, and
                facilitate growth strategies. Your success is our priority, and
                we're dedicated to helping your product thrive. 🌟
              </p>
            </div>
            <div className="ftext">
              <h2 className="text-center content_heading_title">
                📞 Get Started Today! 📞
              </h2>
              <p>
                At ScryptHub, we're dedicated to turning your product ideas into
                reality. Let's collaborate to create products that resonate with
                your audience and drive your business forward.
                <Link to="/contact-us"> Contact us </Link>today to embark on a
                journey of innovation and success. 🚀🌟
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
