import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
const MobileQa = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="image_container">
          <img className="imag" src="/images/mobile-QA.png" alt="Mobile QA" />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="content_container">
            <h2 className="content_heading_title">
              📱 Mobile QA Services: Ensuring Excellence in App Development 🚀
            </h2>
            <p>
              At ScryptHub, we understand that a seamless mobile application can
              make all the difference in the digital world. That's why we offer
              top-notch Mobile Quality Assurance (QA) services to ensure your
              mobile apps are bug-free, user-friendly, and perform at their best
              across various devices and platforms. 🌟
            </p>
            <h3 className="content_heading">
              Our Mobile QA Testing Approach 🧪
            </h3>
            <div className="content_phases">
              <p>
                <b>
                  <span className="emoji">🔍</span>Functional Testing:
                </b>
                We meticulously examine every feature of your mobile app to
                ensure it performs as intended. From login processes to in-app
                purchases, we leave no stone unturned.
              </p>
              <p>
                <b>
                  <span className="emoji">📱</span>Compatibility Testing:
                </b>
                We test your app on a wide range of devices, screen sizes, and
                operating systems to guarantee a consistent and delightful user
                experience for all.
              </p>
              <p>
                <b>
                  <span className="emoji">🚀</span>Performance Testing:
                </b>
                Speed matters! We evaluate your app's loading times,
                responsiveness, and overall performance to keep your users
                engaged.
              </p>
              <p>
                <b>
                  <span className="emoji">🔒</span>Security Testing:
                </b>
                Your users' data is valuable. We conduct thorough security
                assessments to identify vulnerabilities and protect your app
                from potential threats.
              </p>
              <p>
                <b>
                  <span className="emoji">🧘‍♀️</span>Usability Testing:
                </b>
                We put ourselves in your users' shoes and assess the app's
                user-friendliness. Intuitive navigation and a seamless interface
                are non-negotiable.
              </p>
              <p>
                <b>
                  <span className="emoji">🌐</span>Localization and
                  Internationalization Testing:
                </b>
                Going global? We make sure your app is culturally sensitive,
                language-ready, and appeals to diverse audiences.
              </p>
              <p>
                <b>
                  <span className="emoji">📱</span>Device and OS Compatibility:
                </b>
                We ensure your app works flawlessly across a myriad of devices,
                from the latest flagship smartphones to older models.
              </p>
              <p>
                <b>
                  <span className="emoji">🔥</span>Regression Testing:
                </b>
                With every update, we ensure that new features don't break
                existing functionalities. Your app remains solid and dependable.
              </p>
              <p>
                <b>
                  <span className="emoji">👩‍💻</span>Automation Testing:
                </b>
                Speed up your testing process with automation. We develop robust
                test scripts and frameworks for continuous quality assurance.
              </p>
            </div>
            <div className="ftext">
              <h2 className="text-center content_heading_title">
                📞 Get Started Today! 📞
              </h2>
              <p>
                When you choose ScryptHub for your Mobile QA needs, you're not
                just getting bug-free apps; you're getting a partner committed
                to your success. Let's build outstanding mobile experiences
                together! <Link to="/contact-us"> Contact us </Link>today to
                discuss your project and how our Mobile QA services can elevate
                your app development journey. Your success is our priority! 🚀
                🌟
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileQa;
