import React from "react";
// import { Link } from "react-router-dom";

const CardComponent = ({ title, description, iconClass }) => {
  return (
    <div className="bg-white p-2 rounded-4 f-icon-hover row card_c">
      <div className="row pad_col ">
        <div className="col">
          <h6 className="mb-3">{title}</h6>
        </div>
        <div className="f-icon-shape-sm col">
          <img
            width="100%"
            src={iconClass}
            alt={title}
            className="color_icon2"
          />
        </div>
      </div>
      <div className="row pad_text">
        <p className="mb-4">{description}</p>
      </div>
    </div>
  );
};
export default CardComponent;
