const articles = [
  {
    title: "The Future of Web: Progressive Web Apps (PWAs)",
    imageSrc: "images/blog/01.jpg",
    date: "October 04, 2023",
  },
  {
    title: "Progressive Enhancement vs. Graceful Degradation",
    imageSrc: "images/blog/02.jpg",
    date: "October 04, 2023",
  },
  {
    title: " Deep Dive: Exploring the Advanced Features of ES6.",
    imageSrc: "images/blog/03.jpg",
    date: "October 04, 2022",
  },
];
export default articles;
