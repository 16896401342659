import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const form = useRef();

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@gmail\.com$/;
    return emailPattern.test(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Client-side validation
    let formErrors = {};

    if (!name) formErrors.name = "Name is required.";
    if (!email) formErrors.email = "Email is required.";
    else if (!validateEmail(email))
      formErrors.email = "Valid Gmail address is required.";
    if (!message) formErrors.message = "Please leave us a message.";

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    emailjs
      .sendForm(
        "service_gxv8pyl",
        "template_h1krfb5",
        form.current,
        "CRSzECz23dIT88YKp"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Message sent");
          setName("");
          setEmail("");
          setMessage("");
          setErrors({});
          toast.success("Email sent successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="bg-white shadow p-5 rounded-4">
      <Form
        id="contact-form"
        className="row"
        innerRef={form}
        onSubmit={sendEmail}
      >
        <div className="messages"></div>
        <FormGroup className="form-group col-md-12">
          <Label className="font-w-6">Name</Label>
          <Input
            type="text"
            name="user_name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            required
            data-error="Name is required."
          />
          {errors.name && <div className="text-danger">{errors.name}</div>}
        </FormGroup>
        <FormGroup className="form-group col-md-12">
          <Label className="font-w-6">Email Address</Label>
          <Input
            type="email"
            name="user_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your Gmail address"
            required
            data-error="Valid Gmail address is required."
          />
          {errors.email && <div className="text-danger">{errors.email}</div>}
        </FormGroup>
        <FormGroup className="form-group col-md-12">
          <Label className="font-w-6">Message</Label>
          <Input
            type="textarea"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="form-control rounded-4 h-auto"
            placeholder="Type your message here"
            rows="4"
            required
            data-error="Please leave us a message."
          />
          {errors.message && (
            <div className="text-danger">{errors.message}</div>
          )}
        </FormGroup>
        <Col className="mt-4 text-center">
          <Button color="primary" type="submit">
            Send Message
          </Button>
        </Col>
      </Form>
      <ToastContainer />
    </div>
  );
};

export default ContactForm;
