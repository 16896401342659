import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
const QaaS = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="image_container">
          <img
            className="imag"
            src="/images/QA-as-a-service.png"
            alt="Web App. Development"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="content_container">
            <h2 className="content_heading_title">
              🌟 Quality as a Service (QaaS) 🌟
            </h2>
            <p>
              At ScryptHub, we understand that delivering flawless software is
              essential for your success. That's why we offer Quality as a
              Service (QaaS) as an integral part of our comprehensive software
              development solutions.
            </p>
            <h3 className="content_heading">✨ What is QaaS? ✨</h3>
            <p>
              QaaS is your ticket to achieving software excellence. It's a
              systematic and proactive approach to ensuring that your software
              applications perform seamlessly, meet industry standards, and
              exceed user expectations. Our QaaS team is here to be your quality
              guardians throughout the development process.
            </p>
            <h3 className="content_heading">🛠️ Our QaaS Toolbox 🛠️</h3>
            <p>
              We bring a potent blend of expertise, technology, and
              methodologies to guarantee top-notch software quality:
            </p>
            <div className="content_phases">
              <p>
                <b>
                  <span className="emoji">🔍</span>Test Automation:
                </b>
                Our automated testing procedures ensure rigorous, consistent,
                and repeatable evaluations.
              </p>
              <p>
                <b>
                  <span className="emoji">🕵️</span>Manual Testing:
                </b>
                Our expert testers emulate real-world user scenarios, uncovering
                issues that automated tests may miss.
              </p>
              <p>
                <b>
                  <span className="emoji">📈</span>Performance Testing:
                </b>
                We assess your software's stability, scalability, and
                responsiveness to ensure optimal performance under any load.
              </p>
              <p>
                <b>
                  <span className="emoji">🔒</span>Security Testing:
                </b>
                Protect your software from vulnerabilities with thorough
                security assessments and penetration testing.
              </p>
              <p>
                <b>
                  <span className="emoji">🌐</span>Compatibility Testing:
                </b>
                We validate that your software works flawlessly across various
                browsers, devices, and platforms.
              </p>
              <p>
                <b>
                  <span className="emoji">📝</span>Documentation:
                </b>
                Our detailed documentation ensures that your software is
                well-documented, making future maintenance and updates
                hassle-free.
              </p>
              <p>
                <b>
                  <span className="emoji">🔄</span>Continuous Integration &
                  Continuous Delivery (CI/CD):
                </b>
                We integrate QaaS seamlessly into your development pipeline for
                ongoing quality assurance.
              </p>
            </div>
            <h3 className="content_heading">✅ The QaaS Advantage ✅</h3>
            <p>With QaaS from ScryptHub, you can expect:</p>
            <div className="content_phases">
              <p>
                <b>
                  <span className="emoji">🔧</span>Bug-Free Software:
                </b>
                We catch and fix issues before they reach your users.
              </p>
              <p>
                <b>
                  <span className="emoji">🚀</span>Faster Time-to-Market:
                </b>
                Efficient testing means quicker releases.
              </p>
              <p>
                <b>
                  <span className="emoji">🔒</span>Enhanced Security:
                </b>
                Protect your data and reputation with our security measures.
              </p>
              <p>
                <b>
                  <span className="emoji">📈</span>Optimized Performance:
                </b>
                Deliver a high-performance software experience.
              </p>
              <p>
                <b>
                  <span className="emoji">🌐</span>Cross-Platform Excellence:
                </b>
                Ensure a consistent user experience on all devices.
              </p>
              <p>
                <b>
                  <span className="emoji">📊</span>Cost Savings:
                </b>
                Avoid costly post-release bug fixes.
              </p>
            </div>
            <h3 className="content_heading">💡 Why Choose ScryptHub? 💡</h3>
            <p>
              Our QaaS team comprises dedicated experts who are passionate about
              quality. We take pride in delivering software that not only meets
              but exceeds your expectations. With our holistic QaaS approach,
              you can focus on your core business while we handle the quality
              assurance, ensuring your software shines in the competitive
              market.
            </p>
            <div className="ftext">
              <h2 className="text-center content_heading_title">
                📞 Get Started Today! 📞
              </h2>
              <p>
                Ready to take your software quality to the next level?
                <Link to="/contact-us"> Contact us </Link> today to learn more
                about how QaaS from ScryptHub can transform your software
                development process. Let's build a flawless future together!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QaaS;
