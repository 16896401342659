import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
const CloudAplication = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="image_container">
          <img
            className="imag"
            src="/images/CLOUD APPLICATION.png"
            alt="Cloud Application"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="content_container">
            <h2 className="content_heading_title">
              🌟 Introducing Our Cloud Application 🌟
            </h2>
            <p>
              Welcome to the future of cloud computing! At ScryptHub, we're
              thrilled to present our cutting-edge cloud application, designed
              to revolutionize the way you manage your data and applications. 🚀
            </p>
            <h3 className="content_heading">🔑 Key Features:</h3>
            <div className="content_phases">
              <p>
                <b>
                  <span className="emoji">☁️</span>Scalability & Flexibility:
                </b>
                Our cloud application offers unparalleled scalability, allowing
                you to expand or shrink your resources as your business needs
                change. Whether you're a startup or a Fortune 500 company, our
                cloud can adapt to your growth.
              </p>
              <p>
                <b>
                  <span className="emoji">🌐</span>Global Reach:
                </b>
                Access your data and applications from anywhere in the world
                with our global network of data centers. Enjoy low-latency,
                high-speed connections to ensure a seamless user experience.
              </p>
              <p>
                <b>
                  <span className="emoji">🛡️</span>Security First:
                </b>
                Your data's security is our top priority. We employ
                state-of-the-art encryption, multi-factor authentication, and
                robust access controls to keep your information safe and sound.
              </p>
              <p>
                <b>
                  <span className="emoji">🤖</span>AI-Powered Insights:
                </b>
                Leverage the power of artificial intelligence to gain actionable
                insights from your data. Our application offers advanced
                analytics and machine learning capabilities to help you make
                data-driven decisions.
              </p>
              <p>
                <b>
                  <span className="emoji">🌐</span>Multi-Cloud Compatibility:
                </b>
                We understand that no two businesses are the same. That's why
                our cloud application seamlessly integrates with popular public
                clouds like AWS, Azure, and Google Cloud, giving you the freedom
                to choose the best solutions for your unique needs.
              </p>
              <p>
                <b>
                  <span className="emoji">🌐</span>Hybrid Cloud Ready:
                </b>
                For those looking to maintain some on-premises infrastructure,
                our hybrid cloud support ensures a smooth transition to the
                cloud while preserving your existing investments.
              </p>
              <p>
                <b>
                  <span className="emoji">🔄</span>Auto-Scaling:
                </b>
                Say goodbye to manual resource management. Our auto-scaling
                feature automatically adjusts resources based on demand,
                optimizing performance and cost-efficiency.
              </p>
              <p>
                <b>
                  <span className="emoji">💼</span>Enterprise-Grade Reliability:
                </b>
                Downtime is not an option. Our cloud application boasts 99.999%
                uptime, ensuring your business operations run smoothly 24/7.
              </p>
              <p>
                <b>
                  <span className="emoji">📈</span>Cost Control:
                </b>
                Keep a close eye on your cloud spending with our cost monitoring
                and optimization tools. Say hello to cost-effective cloud
                management.
              </p>
              <p>
                <b>
                  <span className="emoji">📱</span>Mobile Access:
                </b>
                Manage your cloud resources on the go with our mobile app,
                giving you full control at your fingertips.
              </p>
              <p>
                <b>
                  <span className="emoji">💬</span>24/7 Support:
                </b>
                Our dedicated support team is always available to assist you.
                Whether it's a technical issue or general guidance, we've got
                your back.
              </p>
              <p>
                <b>
                  <span className="emoji">🌍</span>Environmentally Conscious:
                </b>
                We're committed to sustainability. Our cloud infrastructure is
                designed with energy efficiency in mind, reducing your carbon
                footprint.
              </p>
              <p>
                <b>
                  <span className="emoji">🔄</span>Continuous Updates:
                </b>
                We're constantly improving and innovating our cloud application.
                You'll receive regular updates with new features and
                enhancements to keep your business at the forefront of
                technology.
              </p>
            </div>
            <div className="ftext">
              <h2 className="text-center content_heading_title">
                📞 Get Started Today! 📞
              </h2>
              <p>
                Experience the future of cloud computing with ScryptHub! Join
                the thousands of businesses worldwide that trust us to power
                their digital transformation. Embrace the cloud revolution
                today!
                <p>
                  Ready to get started?
                  <Link to="/contact-us"> Contact us </Link> now to explore how
                  our cloud application can supercharge your business. 🚀 🌟
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudAplication;
