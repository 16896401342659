import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const ContactInfo = ({ contactInfo }) => {
  return (
    <div>
      <h3 className="font-w-6 mb-5">Get in touch is easy! </h3>
      <Row>
        {contactInfo.map((info, index) => (
          <Col md={6} key={index}>
            {/* <h6>{info.region}</h6> */}
            <ul className="contact-info list-unstyled mt-4">
              {/* <li className="mb-4 text-dark text-center">
                <i className="text-primary fs-4 align-middle bi bi-geo-alt me-2"></i>{" "}
                {info.address}
              </li> */}
              {/* <li className="mb-4">
                <i className="text-primary fs-4 align-middle bi bi-telephone me-2"></i>
                <a className="btn-link" href={`tel:${info.phone}`}>
                  {info.phone}
                </a>
              </li> */}
              <li className="text-dark text-center">
                <i className="text-primary fs-4 align-middle bi bi-envelope me-2"></i>
                <a className="btn-link " href={`mailto:${info.email}`}>
                  {info.email}
                </a>
                <a
                  href="https://www.linkedin.com/company/scrypthub"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark fs-4 linkd_logo "
                >
                  <i className="bi bi-linkedin"></i>
                </a>
                <a
                  href="https://twitter.com/ScryptHub"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark fs-4"
                >
                  <i className="bi bi-twitter  twiter_margin"></i>
                </a>
              </li>
            </ul>
          </Col>
        ))}
        {/* <div className="mt-4 icon_padding">
          <div className="m-2 icon_border">
            <Link to="/" className="text-success fs-4 ">
            <i className="bi bi-linkedin"></i>
            </Link>
          </div>
        </div> */}
      </Row>
    </div>
  );
};

export default ContactInfo;
