import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
const WebAppDevelopment = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="image_container">
          <img
            className="imag"
            src="/images/WEB APPLICATION.png"
            alt="Web App. Development"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="content_container">
            <h2 className="content_heading_title">
              🌐 Welcome to our Web Application Services! 🚀
            </h2>
            <p>
              At Scrypthub, we are passionate about crafting cutting-edge web
              applications that empower businesses and individuals to thrive in
              the digital age. Our team of expert developers and designers work
              tirelessly to create web applications that not only meet but
              exceed your expectations. Here are some key features that set us
              apart:
            </p>
            <div className="content_phases">
              <p>
                <b>
                  <span className="emoji">1️⃣</span>User-Centric Design:
                </b>
                Our applications are designed with the end-user in mind. We
                prioritize user experience (UX) and ensure that our web apps are
                intuitive, visually appealing, and easy to navigate.
              </p>
              <p>
                <b>
                  <span className="emoji">2️⃣</span>Cross-Platform Compatibility:
                </b>
                Whether your audience is on a desktop, tablet, or smartphone,
                our web applications adapt seamlessly to different screen sizes
                and devices, ensuring a consistent experience.
              </p>
              <p>
                <b>
                  <span className="emoji">3️⃣</span>Performance Optimization:
                </b>
                Speed matters! Our web apps are optimized for lightning-fast
                loading times, providing a smooth and responsive user
                experience.
              </p>
              <p>
                <b>
                  <span className="emoji">4️⃣</span>Security Fortification:
                </b>
                Your data is precious. We implement robust security measures to
                protect your web application from threats, ensuring your users'
                information remains safe.
              </p>
              <p>
                <b>
                  <span className="emoji">5️⃣</span>Scalability:
                </b>
                As your business grows, so should your web application. We build
                scalable solutions that can expand to meet your evolving needs
                without compromising on performance.
              </p>
              <p>
                <b>
                  <span className="emoji">6️⃣</span>Customization:
                </b>
                No two businesses are alike, and neither are our web
                applications. We tailor our solutions to match your unique
                requirements, ensuring you get exactly what you need.
              </p>
              <p>
                <b>
                  <span className="emoji">7️⃣</span>Seamless Integration:
                </b>
                We understand that your web application may need to work with
                other systems. We offer seamless integration with third-party
                services and APIs to enhance functionality.
              </p>
              <p>
                <b>
                  <span className="emoji">8️⃣</span>Responsive Support:
                </b>
                Our dedicated support team is available around the clock to
                assist you with any issues, updates, or questions you may have.
              </p>
              <p>
                <b>
                  <span className="emoji">9️⃣</span>Analytics and Insights:
                </b>
                Knowledge is power. We provide robust analytics tools that allow
                you to track user behavior, gather insights, and make
                data-driven decisions.
              </p>
              <p>
                <b>
                  <span className="emoji">🔟</span>Scalable Growth:
                </b>
                Our web applications are built to support your business's
                growth. Whether you're a startup looking to make your mark or an
                established enterprise aiming for digital transformation, we've
                got you covered.
              </p>
            </div>
            <div className="ftext">
              <h2 className="text-center content_heading_title">
                📞 Get Started Today! 📞
              </h2>
              <p>
                Join the ranks of satisfied clients who have experienced the
                magic of our web applications.
                <Link to="/contact-us"> Contact us </Link> today to discuss your
                project, and let's embark on a journey to digital success
                together! 🚀 🌟
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebAppDevelopment;
