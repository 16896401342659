import React from "react";
import PageHeading from "../../Components/page-heading/Page_Heading";
import { Col, Container, Row } from "reactstrap";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";
import Footer from "../../Components/footer/Footer";

function Contatct() {
  const secondBreadcrumb = {
    label: "Contact Us",
    link: "/contact-us",
    active: true,
  };
  const contactInfo = [
    {
      // address: "Gulberg III, 54000, Lahore, PK",
      email: "hello@scrypthub.com",
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <PageHeading title="Contact Us" secondBreadcrumb={secondBreadcrumb} />
        <section>
          <Container>
            <Row className="align-item-center">
              <Col lg={6} className=" mb-6 mb-lg-0">
                <ContactInfo contactInfo={contactInfo} />
              </Col>
              <Col lg={6} className="col-12 ps-lg-10">
                <ContactForm />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Contatct;
