const blogList = [
  {
    id: 1,
    image: "images/blog/01.jpg",
    date: "October 04, 2023",
    category: "Web Development",
    title: "Progressive Enhancement vs. Graceful Degradation",
    url: "blog-single.html",
    description:
      "Progressive Enhancement is an approach to web development that prioritizes the creation of a core, functional website that works on all browsers and devices.",
  },
  {
    id: 2,
    image: "images/blog/02.jpg",
    date: "October 04, 2023",
    category: "Web Development",
    title: "The Future of Web: Progressive Web Apps (PWAs)",
    url: "blog-single.html",
    description:
      "Progressive Web Apps are a game-changing fusion of web technology and mobile application functionality. ",
  },
  {
    id: 3,
    image: "images/blog/03.jpg",
    date: "October 04, 2003",
    category: "Web Development",
    title: "Deep Dive: Exploring the Advanced Features of ES6.",
    url: "blog-single.html",
    description:
      "ES6 is also known as ECMAScript 2015. ES6 was the second major version of JavaScript and an updated version of ES5 (ECMAScript 5), which was released on June 17, 2015. ",
  },
];

export default blogList;
