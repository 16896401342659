import React from "react";
import { Container, Row, Col } from "reactstrap";
import LottiePlayer from "../player";
// import Bg from "../../../public/js/lottie/about1.json";
import Bg from "./../../assets/lottie/about1.json";

function About() {
  const benefits = [
    {
      icon: "/images/ss-01.png",
      title: "Transform Your Vision into Powerful Software Solutions",
      description:
        "Get custom software solutions that align with your business goals and drive growth.",
    },
    {
      icon: "/images/agile s-01.png",
      title:
        "Ensure Quality & Reliability with Our Comprehensive Testing Services",
      description:
        "Mitigate risks and deliver flawless products with our rigorous testing methodologies.",
    },
    {
      icon: "/images/agile a-01.png",
      title: "Accelerate Your Development Process with Our Agile Approach",
      description:
        "Save time and resources by leveraging our expertise in efficient software development.",
    },
  ];
  return (
    <div>
      <section>
        <Container>
          <Row className="align-items-center">
            <Col xs={12} lg={6} className="mb-8 mb-lg-0 order-lg-1">
              <LottiePlayer src={Bg} />
            </Col>
            <Col xs={12} lg={6}>
              <div>
                <h2 className="mb-5">
                  Benefits Of Working With Awesome ScryptHub
                </h2>
              </div>
              {benefits.map((benefit, index) => (
                <div className="d-flex align-items-start mb-4" key={index}>
                  <div className="me-3">
                    {/* <i className={`bi ${benefit.icon} fs-3 color_icon`}></i> */}
                    <img
                      width="100%"
                      src={benefit.icon}
                      alt=""
                      className="color_icon"
                    />
                  </div>
                  <div>
                    <h5 className="mb-2">{benefit.title}</h5>
                    <p className="mb-0">{benefit.description}</p>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default About;
