import React from "react";
import { Container, Row, Col } from "reactstrap";
import CardComponent from "./FeatureCard";
import LottiePlayer from "../player";
// import Bg from "../../../public/js/lottie/feature.json";
import Bg from "../../assets/lottie/feature.json";

function Feature() {
  const cardData = [
    {
      title: "Web Development",
      description:
        "Transform your online presence with ScryptHub's expert web development. We create stunning websites that make a lasting impression.",
      backgroundColor: "#ffebda",
      iconClass: "/images/wd-01.png",
    },
    {
      title: "Mobile Development",
      description:
        "Bring your app ideas to life with ScryptHub's mobile development expertise. We design and build user-friendly mobile apps for success.",
      backgroundColor: "#dbf9f9",
      iconClass: "/images/md-01.png  ",
    },
    {
      title: "UI/UX Designing",
      description:
        "Elevate your digital experience with ScryptHub's UI/UX design expertise. We craft user interfaces that captivate and user experiences that resonate.",
      backgroundColor: "#ffebda",
      iconClass: "/images/uiux-01.png",
    },
    {
      title: "Software Testing",
      description:
        "Ensure software perfection with ScryptHub's thorough testing services. We uncover bugs and optimize performance for a seamless user experience.",
      backgroundColor: "#faedff",
      iconClass: "/images/testing-01.png",
    },
  ];

  return (
    <div>
      <section className="px-lg-7 px-2 pb-0 pt-0">
        <div className="bg-light py-10 px-3 px-lg-8 rounded-4 position-relative overflow-hidden">
          <Container className="z-index-1">
            <Row className="align-items-end justify-content-between mb-6">
              <Col xs={12} lg={6} xl={5}>
                <div>
                  <h2>We Provide Quality Features Services</h2>
                </div>
              </Col>
              <Col xs={12} lg={6} xl={5} className="ms-auto mt-5 mt-lg-0">
                {/* <p className="lead">
                  We are a team of experienced developers who are passionate
                  about their work. No coding required to make customizations.
                </p> */}
              </Col>
            </Row>
            <Row className="gx-5">
              {cardData.map((data, index) => (
                <Col
                  style={{
                    marginTop: "2.5rem",
                    "@media screen and (minWidth: 700px)": { marginTop: 0 },
                  }}
                  lg={3}
                  md={6}
                  key={index}
                >
                  <CardComponent
                    title={data.title}
                    description={data.description}
                    backgroundColor={data.backgroundColor}
                    iconClass={data.iconClass}
                  />
                </Col>
              ))}
            </Row>
          </Container>
          <div className="position-absolute animation-2">
            <LottiePlayer src={Bg} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Feature;
