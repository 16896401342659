import React from "react";
import { useParams } from "react-router-dom";
import blogList from "../../api/blogList";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardImg,
  CardTitle,
} from "reactstrap";
import PageHeading from "../page-heading/Page_Heading";

function DetailPage() {
  const { id } = useParams;
  const blog = blogList.find((blog) => blog.id === parseInt(id));
  const firstBreadcrumb = { label: "Pages", link: "/blogs" };
  const secondBreadcrumb = {
    label: "Blog Card",
    link: "/blog-single",
    active: true,
  };
  if (!blog) return <div>Blogs Not Found</div>;
  return (
    <div className="page-wrapper">
      <PageHeading
        title="Blog Card"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Card className="post-card rounded border-0 shadow-none">
                <CardImg
                  top
                  className="rounded img-fluid"
                  src={blog.image}
                  alt="Image"
                />
                <CardBody className="pt-4 pb-0 px-0">
                  <div>
                    <div className="d-inline-block bg-light text-center px-2 py-1 rounded me-2">
                      <i className="bi bi-calendar3 text-dark me-1"></i>
                      {blog.date}
                    </div>
                    <a className="d-inline-block btn-link" href="/">
                      {blog.category}
                    </a>
                  </div>
                  <CardTitle tag="h2">{blog.title}</CardTitle>
                </CardBody>
              </Card>
              <p className="mt-5 mb-0 lead">{blog.description}</p>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default DetailPage;
