import React, { useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import emailjs from "@emailjs/browser";
import LottiePlayer from "../player";
import Bg from "../../assets/lottie/heroBanner1.json";
import Bg2 from "../../assets/lottie/heroBanner2.json";

function HeroBanner1() {
  // const [email, setEmail] = useState("");
  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm('service_gxv8pyl', 'template_h1krfb5', form.current, 'CRSzECz23dIT88YKp')
  //     .then((result) => {
  //         console.log(result.text);
  //         console.log("messgaae send");
  //         // setName("");
  //         setEmail("");
  //         // setMessage("");
  //     }, (error) => {
  //         console.log(error.text);
  //     });
  // };

  return (
    <div>
      <section className="overflow-hidden position-relative">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} lg={6} className="order-lg-1 ms-auto mb-lg-0">
              <LottiePlayer src={Bg2} />
            </Col>
            <Col xs={12} lg={5}>
              <h1 className="font-w-4">
                Build your
                <span className="title-bg position-relative d-inline-block">
                  Product
                </span>
                <br className="d-md-block d-none" /> with{" "}
                <span className="logoColor">
                  Scrypt<span className="logoColorB">(</span>Hub
                  <span className="logoColorB">)</span>
                  <span className="comas">;</span>
                </span>
              </h1>
              <p className="lead text-dark mb-5 slogan-text">
                <span className="slogon1">Scripting Solutions,</span>{" "}
                <span className="slogon2">Your Innovation Hub</span>
              </p>
              {/* <Form id="mc-form1" className="group" innerRef={form} onSubmit={sendEmail}>
                <FormGroup className="bg-light p-3 rounded-4 z-index-1 mb-0 d-flex align-items-center">
                  <Input
                    type="email"
                    //value=""
                    name="user_email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="email  border-0 shadow-sm me-2 bg-white"
                    id="mc-email1"
                    placeholder="Email Address"
                    required=""
                    style={{ flexShrink: 1 }}
                  />
                  <Button color="dark" style={{ flexShrink: 0 }} type="submit">
                    Get Started
                  </Button>
                </FormGroup>
              </Form> */}
            </Col>
          </Row>
        </Container>
        <div className="position-absolute animation-1">
          <LottiePlayer src={Bg} />
        </div>
      </section>
    </div>
  );
}

export default HeroBanner1;
