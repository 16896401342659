import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
const PerformanceTesting = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="image_container">
          <img
            className="imag"
            src="/images/performance-testing.png"
            alt="API Testing"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="content_container">
            <h2 className="content_heading_title">
              Unleash Peak Performance with Our Testing Services 🚀
            </h2>
            <p>
              At ScryptHub, we understand that delivering high-quality software
              is more than just coding. It's about ensuring that your
              applications run smoothly, efficiently, and perform at their best.
              That's why we offer a range of cutting-edge performance testing
              services to guarantee your software's optimal performance.🛠️
            </p>
            <h3 className="content_heading">
              🎯 What Types of Performance Testing We Provide:
            </h3>
            <div className="content_phases">
              <p>
                <b>1. Load Testing 🏋️‍♂️</b> Ensure your software can handle the
                anticipated user load without crashing. Our load testing
                simulates real-world user traffic to identify bottlenecks and
                optimize system resources.:
              </p>
              <p>
                <b>2. Stress Testing 🧘‍♂️</b> Push your software to its limits and
                beyond! We'll determine how your system performs under extreme
                conditions to fortify its resilience and reliability.
              </p>
              <p>
                <b>3. Scalability Testing 📈</b> Test the scalability of your
                software to meet growing user demands. We help you plan for
                future growth by assessing how well your system can expand.
              </p>
              <p>
                <b>4. Endurance Testing ⌛</b>Ensure your software can handle
                prolonged usage without performance degradation. We simulate
                extended usage scenarios to validate system stability.
              </p>
              <p>
                <b>5. Response Time Testing ⏱️</b> Measure the responsiveness of
                your applications. We'll help you identify and eliminate any
                delays that could impact user satisfaction.
              </p>
            </div>
            <h3 className="content_heading">
              📈 The Results Speak for Themselves:
            </h3>
            <p>
              Our performance testing services have helped numerous clients
              boost their software's reliability, scalability, and overall
              performance. With our assistance, you can achieve faster load
              times, improved user experiences, and enhanced customer
              satisfaction.
            </p>
            <div className="ftext">
              <h2 className="text-center content_heading_title">
                📞 Get Started Today! 📞
              </h2>
              <p>
                Don't let performance issues hold your software back.
                <Link to="/contact-us"> Contact us </Link>
                today to schedule a consultation and take your software's
                performance to the next level. 🚀 🌟
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceTesting;
