import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
const MobileAppDevelopment = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="image_container">
          <img
            className="imag"
            src="/images/Mobile-development.png"
            alt="Mobile App. development"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="content_container">
            <h2 className="content_heading_title">
              📱 Welcome to our Mobile Development Services! 🚀
            </h2>
            <p>
              At ScryptHub, we're passionate about creating mobile applications
              that stand out in the crowded app market. Our team of expert
              developers and designers are committed to turning your mobile app
              vision into a reality. Here are some of our key features and why
              you should choose us:
            </p>
            <div className="content_phases">
              <p>
                <b>
                  <span className="emoji">🎯</span>Custom App Development:
                </b>
                We understand that your app needs to be unique and tailored to
                your business objectives. Our team specializes in custom app
                development to ensure your app is one-of-a-kind.
              </p>
              <p>
                <b>
                  <span className="emoji">🌐</span>Cross-Platform Expertise:
                </b>
                We excel in developing apps for both iOS and Android platforms.
                With our cross-platform expertise, we'll help you reach a wider
                audience without compromising on quality.
              </p>
              <p>
                <b>
                  <span className="emoji">📈</span>Scalability:
                </b>
                We design apps that can grow with your business. Our scalable
                solutions ensure that your app can handle increased user loads
                and new features as your business expands.
              </p>
              <p>
                <b>
                  <span className="emoji">💡</span>Innovative UI/UX Design:
                </b>
                We believe in creating not just functional but also visually
                appealing apps. Our UI/UX designers are masters at crafting
                intuitive and beautiful user interfaces.
              </p>
              <p>
                <b>
                  <span className="emoji">🛡️</span>Security First:
                </b>
                Security is paramount in today's digital landscape. We implement
                robust security measures to safeguard your app and user data.
              </p>
              <p>
                <b>
                  <span className="emoji">🚀</span>Rapid Prototyping:
                </b>
                We understand the importance of speed to market. Our rapid
                prototyping process ensures that you get a working prototype of
                your app quickly for testing and feedback.
              </p>
              <p>
                <b>
                  <span className="emoji">📊</span>Analytics and Optimization:
                </b>
                We provide built-in analytics tools to help you track user
                behavior and app performance. This data-driven approach allows
                for continuous optimization.
              </p>
              <p>
                <b>
                  <span className="emoji">📲</span>Native and Hybrid
                  Development:
                </b>
                Whether you need a native app for top-notch performance or a
                hybrid app for cost-effectiveness, we've got you covered.
              </p>
              <p>
                <b>
                  <span className="emoji">🌐</span>Global Reach:
                </b>
                We have a global presence, making us your ideal partner for apps
                designed to reach a diverse and international user base.
              </p>
              <p>
                <b>
                  <span className="emoji">🔒</span>Data Protection:
                </b>
                Rest easy knowing that we prioritize data protection and
                compliance with industry standards and regulations.
              </p>
              <p>
                <b>
                  <span className="emoji">📢</span>Continuous Support:
                </b>
                Our commitment to your project doesn't end with the app launch.
                We provide ongoing support and updates to keep your app running
                smoothly.
              </p>
              <p>
                <b>
                  <span className="emoji">📈</span>Proven Track Record:
                </b>
                Our portfolio includes successful apps across various
                industries, demonstrating our ability to deliver results.
              </p>
              <p>
                <b>
                  <span className="emoji">🤝</span>Collaborative Approach:
                </b>
                We consider you a partner, not just a client. We work closely
                with you at every stage to ensure your vision is realized.
              </p>
            </div>
            <div className="ftext">
              <h2 className="text-center content_heading_title">
                📞 Get Started Today! 📞
              </h2>
              <p>
                Ready to turn your app idea into a mobile sensation? Let's chat!
                <Link to="/contact-us"> Contact us </Link> today to kickstart
                your mobile app development journey. 🚀 🌟
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAppDevelopment;
