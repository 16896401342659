import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  // ListGroup,
  // ListGroupItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// import { ToastContainer, toast } from "react-toastify";
import ContactForm from "../../Pages/contact/ContactForm";
function Header() {
  // const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the window width is below a certain value (e.g., 768px)
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleNav = () => {
    setIsCollapsed(!isCollapsed);
  };
  const toggle = () => setIsOpen(!isOpen);

  const navItems = [
    {
      label: "Home",
      to: "/",
      dropdown: false,
    },
    {
      label: "Build",
      to: "/build",
      dropdown: true,
      items: [
        { label: "Product Development", to: "/product-development" },
        { label: "Web Development", to: "/web-app-development" },
        { label: "Mobile Development", to: "/mobile-app-development" },
        { label: "Cloud Application", to: "/cloud-application" },
        { label: "SaaS Product", to: "/saas-product" },
      ],
    },

    {
      label: "Test",
      to: "/test",
      dropdown: true,
      items: [
        { label: "Quality as a Service (QaaS)", to: "/quality-assurance" },
        { label: "Automation Testing", to: "/automation-testing" },
        { label: "Manual Testing", to: "/manual-testing" },
        { label: "API Testing", to: "/api-testing" },
        { label: "Mobile QA", to: "/mobile-qa" },
        { label: "Performance Testing", to: "/performance-testing" },
        { label: "Usability Testing", to: "/usability-testing" },
      ],
    },
    // {
    //   label: "Blogs",
    //   dropdown: false,
    //   to: "/blog-listing-3",
    // },

    {
      label: "Contact",
      dropdown: false,
      to: "/contact-us",
    },
  ];
  const [dropdownOpen, setDropdownOpen] = useState(
    Array(navItems.length).fill(false)
  );

  const toggleDropdown = (index) => {
    const newArray = [...dropdownOpen];
    newArray[index] = !newArray[index];
    setDropdownOpen(newArray);
  };
  const navigate = useNavigate();

  const handleLinkClick = (to) => {
    navigate(to, { replace: true });
  };

  return (
    <div className="">
      <header className="site-header">
        <div id="header-wrap">
          <Container className="container">
            <Row className="row">
              {/* <!--menu start--> */}
              <Col className="col">
                <Nav className="navbar navbar-expand-lg px-4 shadow bg-white">
                  <NavbarBrand href="/" className="navbar-brand logo">
                    <img src="images/logo-a.png" alt="" />
                  </NavbarBrand>
                  <NavbarToggler onClick={toggleNav} className="me-2" />
                  <Collapse
                    className="navbar-collapse navbar_row"
                    isOpen={!isCollapsed}
                  >
                    <Nav className="navbar-nav">
                      {navItems.map((item, index) =>
                        /* If item has child items */
                        item.dropdown ? (
                          <Dropdown
                            className="dropdown"
                            key={item.label}
                            nav
                            toggle={() => toggleDropdown(index)}
                            isOpen={dropdownOpen[index]}
                          >
                            <DropdownToggle nav caret>
                              {item.label}
                            </DropdownToggle>
                            <DropdownMenu>
                              {item.items.map((subItem) => (
                                <DropdownItem
                                  key={subItem.label}
                                  className=""
                                  onClick={() => handleLinkClick(subItem.to)}
                                >
                                  {subItem.label}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                        ) : (
                          /* Menu item without child items */
                          <NavItem key={item.label}>
                            <Link
                              className="nav-link"
                              to={item.to}
                              onClick={() => handleLinkClick(item.to)}
                            >
                              {item.label}
                            </Link>
                          </NavItem>
                        )
                      )}
                    </Nav>
                  </Collapse>

                  <Nav className="navbar-nav ml-auto align-items-center d-none d-sm-flex">
                    <div className="d-flex align-items-center">
                      {!isMobile && (
                        <div
                          style={{ cursor: "pointer" }}
                          className="ms-2 togglerCanvas d-inline-block border-0 px-2 py-1 bg-white shadow"
                          onClick={toggle}
                        >
                          <i className="bi bi-list-nested fs-3 text-dark"></i>
                        </div>
                      )}
                    </div>
                  </Nav>
                </Nav>
              </Col>
            </Row>
          </Container>
        </div>
      </header>

      <Offcanvas
        className="bg_color_offcanvas"
        direction="end"
        isOpen={isOpen}
        toggle={toggle}
      >
        <OffcanvasHeader>
          <Button
            className="bg-transparent fs-3 ms-auto btn_close"
            onClick={toggle}
          >
            <i className="bi bi-x-octagon"></i>
          </Button>
        </OffcanvasHeader>
        <OffcanvasBody className="px-md-7 pt-2 pb-6">
          <p className="mb-0 lead">
            ScryptHub is the remote all-in-one solution for software designing,
            development and testing. We comprehensive and seamless solutions,
            making innovation and excellence easily accessible.
          </p>
          <div className="form-info border-top border-dark pt-6 mt-6">
            <h5 className=" border-bottom border-white d-inline-block">
              Contact Us
            </h5>
            <ContactForm />
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
}

export default Header;
