import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, ListGroupItem } from "reactstrap";
import LottiePlayer from "../player";
import ContactFooter from "./ContactFooter";
function Footer() {
  // const navigate = useNavigate();

  return (
    <div>
      <footer className="py-sm-11 position-relative overflow-hidden">
        <Container className="z-index-1">
          <Row className="footer_sec">
            <Col md={2} lg={3} className="pe-lg-7">
              <Link className="footer-logo" to="/">
                <img
                  className="img-fluid side-logo mb-3"
                  src="images/logo-a.png"
                  alt=""
                  style={{
                    width: "auto",
                  }}
                />
              </Link>
            </Col>
            <Col md={6} lg={5} className="pe-lg-7 margin_left">
              <p className="my-4">
                {/* ScryptHub is the all-in-one solution for software development
                and testing. ScryptHub provides comprehensive and seamless
                solutions, making innovation and excellence easily accessible. */}
                ScryptHub is the remote all-in-one solution for software
                designing, development and testing. We comprehensive and
                seamless solutions, making innovation and excellence easily
                accessible.
              </p>

              <ListGroupItem className="mb-2 margin_area">
                <span className="margin_text">Email us at &nbsp;</span>
                <Link to="mailto:hello@scrypthub.com" className="margin_lindL">
                  <b className="text-dark">hello@scrypthub.com</b>
                </Link>
                <a
                  href="https://www.linkedin.com/company/scrypthub"
                  target="_blank"
                  className="text-dark fs-3 mrg"
                  rel="noreferrer"
                >
                  <i className={`bi bi-linkedin `}></i>
                </a>
                <a
                  href="https://twitter.com/ScryptHub"
                  target="_blank"
                  className="text-dark fs-3"
                  rel="noreferrer"
                >
                  <i className={`bi bi-twitter`}></i>
                </a>
              </ListGroupItem>
            </Col>
            <Col md={4} lg={4} className="mt-2 mt-lg-0">
              <div className="subscribe-form mt-2">
                <ul className="media-icon list-unstyled">
                  <ListGroupItem className="mb-2">
                    <ContactFooter />
                  </ListGroupItem>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="mt-7">
            <Col className="text-center text-dark">
              <span className="fs-5">&copy;</span> 2023 by{" "}
              <Link href="#" className="logo_name">
                ScryptHub{" "}
              </Link>
              | All Rights Reserved
            </Col>
          </Row>
        </Container>
        <div className="position-absolute animation-1 opacity-1">
          <LottiePlayer src="https://lottie.host/59ba3e9a-bef6-400b-adbb-0eb8c20c9f65/WPBRmjAinD.json" />
        </div>
      </footer>
    </div>
  );
}

export default Footer;
