import React, { useState, useRef, useEffect } from "react";
import TestimonialsCarousel from "./TestimonialCarousel";
import { Col, Row } from "reactstrap";

function Testimonial() {
  const items = [
    {
      id: 1,
      image: "images/testimonial/01.jpg",
      name: "Steve Gascho",
      // position: "CEO",
      quote:
        "SryptHub consistently delivers exceptional results, showcasing outstanding communication, intelligence, and adaptability. They excel at understanding intricate requirements and tackling complex logic challenges with unwavering determination and resilience. I highly recommend their services and look forward to future collaborations",
    },
    {
      id: 2,
      image: "images/testimonial/01.jpg",
      name: "Ray Parker",
      // position: "Founder",
      quote:
        "Working with ScryptHub was truly remarkable. Their deep domain knowledge, exceptional communication skills, and tailored advice were invaluable for our web application planning.",
    },
    {
      id: 3,
      image: "images/testimonial/01.jpg",
      name: "Patrick M",
      // position: "Manager",
      quote:
        "We appreciate ScryptHub's dedication to providing top-notch talent. We highly recommend ScryptHub for their excellent team members and exceptional services.",
    },
  ];

  return (
    <div>
      <section className="px-lg-7 px-2 pb-0">
        <div className="bg-light-2 py-10 px-3 px-lg-8 rounded-4">
          <div className="container">
            <div className="row justify-content-center text-center mb-6">
              <div className="col-12 col-lg-8">
                <div>
                  <h2>Clients All Over The World Sent Us Incredible Feedback</h2>
                </div>
              </div>
            </div>
            <Row className="mx-lg-n10">
              <Col>
                <TestimonialsCarousel items={items} />
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Testimonial;
