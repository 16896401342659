import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactFooter = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Validate email
    const isValidEmail = validateEmail(email);

    if (!isValidEmail) {
      setEmailError("Please enter a valid Gmail address.");
      return;
    }

    // Clear previous errors
    setEmailError("");

    // If email is valid, send the email
    emailjs
      .sendForm(
        "service_gxv8pyl",
        "template_h1krfb5",
        form.current,
        "CRSzECz23dIT88YKp"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Message sent");
          setEmail("");
          setMessage("");
          toast.success("Email sent successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@gmail\.com$/;
    return emailPattern.test(email);
  };

  return (
    <div className="bg-white shadow p-3 rounded-4">
      <Form
        id="contact-form"
        className="row"
        innerRef={form}
        onSubmit={sendEmail}
      >
        <div className="messages"></div>
        <FormGroup className="form-group col-md-12">
          <Label className="font-w-6">Email Address</Label>
          <Input
            type="email"
            className="input_field"
            name="user_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
            required
            data-error="Valid email is required."
          />
          {emailError && <div className="text-danger">{emailError}</div>}
        </FormGroup>
        <FormGroup className="form-group col-md-12">
          <Label className="font-w-6">Message</Label>
          <Input
            type="textarea"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="form-control rounded-4 h-auto"
            placeholder="Type your message here"
            rows="2"
            required
            data-error="Please leave us a message."
          />
        </FormGroup>
        <Col className="mt-1 text-center">
          <Button color="primary" type="submit" className="contact_btn">
            Send Message
          </Button>
        </Col>
      </Form>
      <ToastContainer />
    </div>
  );
};

export default ContactFooter;
