import React from "react";
import { Container, Row, Col } from "reactstrap";
import LottiePlayer from "../player";
// import Bg from "../../../public/js/lottie/counter.json";
import Bg from "../../assets/lottie/counter.json";
import "./angles.css";

function Counter() {
  return (
    <div>
      <section className="pb-0">
        <Container className="container">
          <Row className="align-items-center justify-content-between">
            <Col
              xs={12}
              xl={5}
              lg={6}
              className="mb-8 mb-lg-0 position-relative"
            >
              <Row className="gx-5 align-items-center text-center z-index-1">
                <Col lg={6} md={6}>
                  <div className="counter bg-white rounded-4 p-4 py-7 shadow">
                    <div className="rectangle"></div>
                    <span className="number count text-dark" data-count="10">
                      20
                    </span>
                    <span className="text-dark">+</span>
                    <h6 className="mb-0 text-muted">Completed Sites</h6>
                  </div>
                </Col>
                <Col lg={6} md={6} mt-5>
                  <div className="counter bg-white rounded-4 p-5 py-7 shadow">
                    <i className="bi bi-triangle triabl"></i>
                    <span className="number count text-dark" data-count="450">
                      3
                    </span>
                    <span className="text-dark">+</span>
                    <h6 className="mb-0 text-muted">Departments</h6>
                  </div>
                  <div className="counter bg-white rounded-4 p-3 py-7 shadow mt-7">
                    <div className="circle"></div>
                    <span className="number count text-dark" data-count="666">
                      40
                    </span>
                    <span className="text-dark">+</span>
                    <h6 className="mb-0 text-muted">Happy Customers</h6>
                  </div>
                </Col>
              </Row>
              <div className="position-absolute animation-1 opacity-1">
                <LottiePlayer src={Bg} />
              </div>
            </Col>
            <Col xs={12} xl={6} lg={6}>
              <div>
                <h2>For Seamless Software Experiences</h2>
                <p className="lead mb-4">
                  We empower your businesses through our expertise in designing,
                  development and testing services, ensuring your software and
                  cloud solutions are top-notch.
                </p>
              </div>
              <div className="d-flex align-items-start mb-3">
                <div className="me-3">
                  <i className="bi bi-check2-all fs-2 check_color"></i>
                </div>
                <div>
                  <h6 className="mb-2">Crafting Cutting-Edge Solutions</h6>
                  <p className="mb-0">
                    Unleash Your Vision with Our Software Development Expertise.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="me-3">
                  <i className="bi bi-check2-all fs-2 check_color"></i>
                </div>
                <div>
                  <h6 className="mb-2">Ensuring Flawless Performance</h6>
                  <p className="mb-0">
                    Optimize Your Software with Rigorous Testing and Quality
                    Assurance.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Counter;
